import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { IMAGEURL, BASEURL } from "../config";
import DataTable from "react-data-table-component";
import SoftBox from "components/SoftBox";
import Table from "./DataTable";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const Setting = () => {
  // `id`, `Name`, `email`,
  //  `mobile`, `Type_One_Daily`, `Price`, `Discount`, `password_id`,
  //   `password`,
  //    `images`, `Description`, `Updated_Date`
  const clickhandler = (name) => console.log("delete", name);

  const [Name, setTitle] = useState("");
  const [email, setDescription] = useState("");
  const [mobile, setKey1] = useState("");
  const [Price, setValue1] = useState("");
  const [password, setKey2] = useState("");

  const [editmodal, setEditmodal] = useState(false);

  const [insertmodal, setInsertmodal] = useState(false);

  const [res, setRes] = useState([]);

  const [images, setImage] = useState(null);
  // const [typeval , setTypeval] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowid, setRowid] = useState("");

  const handleChangeRow = (value) => {
    setSelectedRows(value);
  };
  const editRow = (event, Name, email, mobile, password, images, rowid) => {
    console.log(event.target.id + " " + Name);
    //setEditid(event.target.id);
    setEditmodal(true);

    setTitle(Name);
    setDescription(email);
    setKey1(mobile);
    setKey2(password);

    setImage(images);
    setRowid(rowid);
  };
  const deleteRow = (event, rowid) => {
    console.log(event.target.id);

    setRowid(rowid);
    formData.append("rowid", rowid);

    axios({
      method: "post",
      url: BASEURL + "users/deleteCategories",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      console.log(res);
      console.log(res.data);

      if (res.data == "Updated in Categories") {
        alert("Updated Successfully");
        setEditmodal(false);
        //formref.current.reset();
        window.location.reload();
      }
    });
  };
  const viewRow = (event) => {
    console.log(event.target.id);
  };
  const columns = useMemo(
    () => [
      {
        name: "Action",
        width: "130px",
        backgroundColor: "red",
        cell: (row) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <span
              style={{ textAlign: "center", marginLeft: "5px" }}
              type="button"
              id={row.id}
              onClick={(event) =>
                editRow(event, row.Name, row.email, row.mobile, row.password, row.images, row.id)
              }
            >
              <SoftButton variant="text" color="dark">
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
      },

      {
        name: "Name",
        selector: (row) => row.Name,
        sortable: true,
        width: "220px",
        grow: 2,
      },
      {
        name: "password",
        selector: (row) => row.password,
        sortable: true,
        width: "120px",
        grow: 2,
      },
      {
        name: "email",
        selector: (row) => row.email,
        width: "230px",
        sortable: true,
        style: {},
      },
      {
        name: "mobile",
        selector: (row) => row.mobile,
        sortable: true,
        width: "220px",
        grow: 2,
      },
    ],
    []
  );

  const formData = new FormData();
  let imgname = "test";

  const handleEditModal = () => {
    setEditmodal(true);
  };

  const handleEditCloseModal = () => {
    setEditmodal(false);
  };

  const handleInsertModal = () => {
    setInsertmodal(true);
  };

  const handleInsertCloseModal = () => {
    setInsertmodal(false);
  };

  const openModal = () => {
    setInsertmodal(true);
  };

  // function to update state of name with
  // value enter by user in form
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setTitle(e.target.value);
    }
    if (id === "email") {
      setDescription(e.target.value);
    }

    if (id === "mobile") {
      setKey1(e.target.value);
    }
    if (id === "password") {
      setKey2(e.target.value);
    }
  };

  const fetchData = () => {
    axios.get(BASEURL + "Products/Emp/").then((response) => {
      setRes(response.data);
    });
  };
  useEffect(() => {
    fetchData();

    //console.log('state', constants.redPanda);
  }, []);

  let imagearray = [];

  const handleFileInput = (e) => {
    console.log("handleFileInput working!");
    console.log(e.target.files);

    let temparr = [];

    //formData.append('imgfile', e.target.files[0], e.target.files[0].name);
    //setImage(e.target.files[0]);
    // for(var i=0; i < e.target.files.length; i++ )
    // {
    //   //setImage(e.target.files[i]);
    //   imgname = e.target.files[i].name;

    //   imagearray.push(e.target.files[i].name);
    //   formData.append('imageval', e.target.files[i]);

    //   var jsonval = JSON.stringify(Object.assign({}, imagearray)); //JSON.parse(imagearray);
    //   console.log(jsonval);
    //   temparr.push(jsonval);

    //   imagearray = [];

    // }
    formData.delete("imageval");
    formData.append("imageval", e.target.files[0]);

    //formData.append("imgname", new Date().valueOf());
    formData.delete("imgname");
    formData.append("imgname", IMAGEURL + e.target.files[0].name);
  };

  // below function will be called when user
  // click on submit button .
  const handleSubmit = (e) => {
    //e.preventDefault();

    console.log("values Name " + imgname);
    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("email");
    formData.append("email", email);
    formData.delete("mobile");
    formData.append("mobile", mobile);
    formData.delete("password");
    formData.append("password", password);

    axios({
      method: "post",
      url: BASEURL + "users/updateCategories",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      setEditmodal(false);
      fetchData();
      //window.location.reload();
    });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <div className="bg-light" style={{ justifyContent: "left", zIndex: "1500" }}>
          <Modal style={{ zIndex: 1500 }} show={insertmodal} onHide={() => handleInsertModal()}>
            <Modal.Header closeButton onClick={() => handleInsertCloseModal()}>
              Edit
            </Modal.Header>
            <Modal.Body>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Name </span>
                </div>
                <input
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Title"
                  type="text"
                />
              </div>
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Description </span>
                </div>
                <textarea
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Description"
                  value={email}
                  onChange={(e) => handleChange(e)}
                  style={{ height: 100 }}
                ></textarea>
                {/* <input name="" class="form-control" placeholder="Email address" type="email" /> */}
              </div>
              <br />
              <hr />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Key </span>
                </div>
                <input
                  id="mobile"
                  name="mobile"
                  value={mobile}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />

                <div className="input-group-prepend">
                  <span className="input-group-text"> Value </span>
                </div>
                <input
                  id="Price"
                  name="Price"
                  value={Price}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />
              </div>{" "}
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Key </span>
                </div>
                <input
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />

                <br />
                <hr />
                <br />
              </div>{" "}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleInsertCloseModal()}>Close</Button>
              <Button onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
          </Modal>

          {/* </form> */}
        </div>

        <div
          style={{
            width: "98%",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          {/* <DataTable
 
              data={res}
              columns={columns}
              theme="light"
              customStyles={customStyles}
              pagination
              onSelectedRowsChange={handleChangeRow}
              
            /> */}
          <Table data={res} columns={columns} click={clickhandler} />
        </div>

        <Modal style={{ zIndex: 1500 }} show={editmodal} onHide={() => handleEditModal()}>
          <Modal.Header closeButton onClick={() => handleEditCloseModal()}>
            <span
              style={{
                margin: 5,
                textAlign: "left",
                fontSize: "0.85rem",
                fontWeight: "900",

                color: " #8392ab",
                textTransform: "uppercase",
              }}
            >
              {" "}
              Edit
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group ">
              <input
                id="Name"
                name="Name"
                value={Name}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Name"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="email"
                name="email"
                value={email}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="email"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="mobile"
                name="mobile"
                value={mobile}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="mobile"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="password"
                name="password"
                value={password}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="password"
                type="text"
              />
            </div>

            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
              className="btn btn-outer-primary"
              onClick={() => handleEditCloseModal()}
            >
              Close
            </Button>
            <Button
              style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
              className="btn btn-outer-primary"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};
const customStyles = {
  rows: {
    style: {
      color: "#344767",
      fontSize: "0.875rem",
      margin: "0",
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontWeight: "100",
      lineHeight: "1.5",
      textTransform: "uppercase",
      letterSpacing: "0.02857em",
      opacity: "1",
      textTransform: "none",
      verticalAlign: "unset",
    },
  },
  headCells: {
    style: {
      paddingTop: "12px",
      paddingBottom: "10px",
      paddingLeft: "24px",
      paddingRight: "24px",
      textAlign: "left",
      fontSize: "0.65rem",
      fontWeight: "700",
      borderBottom: "0.0625rem solid #e9ecef",
      opacity: "0.9",
      background: "transparent",
      color: " #8392ab",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      paddingLeft: "24px",
      paddingRight: "24px",
      fontWeight: "400",
    },
  },
};

export default Setting;
