import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { IMAGEURL, BASEURL } from "../config";
import { read, utils } from "xlsx";
import SoftBox from "components/SoftBox";
import Table from "./DataTable";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "react-select";

const Products = () => {
  // `id`, `Name`, `NameInKannada`,
  //  `Quantity`, `Type_One_Daily`, `Price`, `Discount`, `category_id`,
  //   `Category`, `Sub_Category`, `Sub_Category_Name`, `Best_Seller`,
  //    `images`, `Description`, `Updated_Date`
  const clickhandler = (name) => console.log("delete", name);

  const [Name, setTitle] = useState("");
  const [NameInKannada, setDescription] = useState("");
  const [Quantity, setKey1] = useState("");
  const [Price, setValue1] = useState("");
  const [Price1, setValuePrice1] = useState("");
  
  const [Category, setKey2] = useState("");
  const [Sub_Category_Name, setValue2] = useState("");
  const [Sub_Category, setValueSub_Category] = useState("");
  const [Best_Seller, setKey3] = useState("");
  const [Description, setValue3] = useState("");

  const [editmodal, setEditmodal] = useState(false);
  const fileInputRef = useRef(null);
  const [insertmodal, setInsertmodal] = useState(false);
  const [pricemodal, setPricemodal] = useState(false);
  const [res, setRes] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [IsBestSeller, setIsBestSeller] = useState([]);
  const [imagesUpladed, setimagesUpladed] = useState(null);
  const [images, setImage] = useState(null);
  // const [typeval , setTypeval] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowid, setRowid] = useState("");
  const [selectedOption, setSelectedOption] = useState("none");

  const handleChangeRow = (value) => {
    setSelectedRows(value);
  };
  const editRow = (
    event,
    Name,
    NameInKannada,
    Quantity,
    Category,
    Best_Seller,
    Price,
    Price1,
    Sub_Category_Name,
    Sub_Category,
    Description,
    images,
    rowid
  ) => {
    console.log(event.target.id + " " + Name);
    //setEditid(event.target.id);
    setEditmodal(true);

    setTitle(Name);
    setDescription(NameInKannada);
    setKey1(Quantity);
    setKey2(Category);
    setKey3(Best_Seller);
    setValue1(Price);
    setValuePrice1(Price1)
    setValue2(Sub_Category_Name);
    setValueSub_Category(Sub_Category);

    setValue3(Description);
    setImage(images);
    setRowid(rowid);
  };
  const deleteRow = (event, rowid) => {
    console.log(event.target.id);

    setRowid(rowid);
    formData.delete("rowid");
    formData.append("rowid", rowid);

    axios({
      method: "post",
      url: BASEURL + "Products/deleteProducts",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      console.log(res);
      console.log(res.data);

      fetchData();
    });
  };
  const viewRow = (event) => {
    console.log(event.target.id);
  };
  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "100px",
        grow: 2,
      },
      {
        name: "Action",
        width: "130px",
        backgroundColor: "red",
        cell: (row) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <span
              style={{ textAlign: "center", marginLeft: "5px" }}
              type="button"
              id={row.id}
              onClick={(event) => {
                setImage(row.images);
                imgname = row.images;
                console.log(imgname);
                editRow(
                  event,
                  row.Name,
                  row.NameInKannada,
                  row.Quantity,
                  row.Category,
                  row.Best_Seller,
                  row.Price,
                  row.Price1,
                  row.Sub_Category_Name,
                  row.Sub_Category,
                  row.Description,
                  row.images,
                  row.id
                );
              }}
            >
              <SoftButton variant="text" color="dark">
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
      },
      {
        name: "Action",
        width: "150px",
        backgroundColor: "red",
        cell: (row) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <span
              style={{ textAlign: "center", marginLeft: "5px" }}
              type="button"
              id={row.id}
              onClick={(event) => {
                var proceed = confirm("Are you sure you want to delete " + row.Name + " product?");
                if (proceed) {
                  deleteRow(event, row.id);
                } else {
                  //don't proceed
                }
              }}
            >
              <SoftButton variant="text" style={{ color: "red" }} color="dark">
                <Icon style={{ color: "red" }}>delete</Icon>&nbsp;Delete
              </SoftButton>
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        selector: true,
      },
      {
        cell: (row) => <img style={{ borderRadius: 10, height: 40 }} src={row.images} />,
      },
      {
        name: "Name",
        selector: (row) => row.Name,
        sortable: true,
        width: "220px",
        grow: 2,
      },
      {
        name: "ItemCode",
        selector: (row) => row.ItemCode,
        sortable: true,
        width: "220px",
        grow: 2,
      },
      
      {
        name: "MRP",
        selector: (row) => row.Price1,
        sortable: true,
        width: "120px",
        grow: 2,
      },
      
      {
        name: "Price",
        selector: (row) => row.Price,
        sortable: true,
        width: "120px",
        grow: 2,
      },


      {
        name: "Name In Kannada",
        selector: (row) => row.NameInKannada,
        width: "230px",
        sortable: true,
        style: {
          fontFamily: "'Nudi 05 k'",
        },
      },
      {
        name: "Quantity",
        selector: (row) => row.Quantity,
        sortable: true,
        width: "120px",
        grow: 2,
      },
      {
        name: "Category",
        selector: (row) => row.Category,
        sortable: true,
        width: "120px",
        grow: 2,
      },

      {
        name: "V ID",
        selector: (row) => row.Sub_Category,
        sortable: true,
        width: "120px",
        grow: 2,
      },
      {
        name: "V Name",
        selector: (row) => row.Sub_Category_Name,
        sortable: true,
        width: "120px",
        grow: 2,
      },
    
      {
        name: "Home Screen",
        selector: (row) => (row.Best_Seller == "1" ? "Yes" : "No"),
        sortable: true,
        width: "150px",
        grow: 2,
      },

      {
        name: "Description",
        selector: (row) => row.Description,
        sortable: true,
        width: "120px",
        grow: 2,
      },
    ],
    []
  );

  const formData = new FormData();
  let imgname = "test";

  const handleEditModal = () => {
    setEditmodal(true);
  };

  const handleEditCloseModal = () => {
    setEditmodal(false);
  };

  const handleInsertModal = () => {
    setInsertmodal(true);
  };

  const handleInsertCloseModal = () => {
    setInsertmodal(false);
  };

  const openpriceModal = () => {
    setPricemodal(true);
  };

  const closepriceModal = () => {
    setPricemodal(false);
  };

  // function to update state of name with
  // value enter by user in form
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setTitle(e.target.value);
    }
    if (id === "NameInKannada") {
      setDescription(e.target.value);
    }

    if (id === "Quantity") {
      setKey1(e.target.value);
    }
    // if (id === "images") {
    //   images(e.target.value);
    // }
    if (id === "Best_Seller") {
      setKey3(e.target.value);
    }
    if (id === "Price") {
      setValue1(e.target.value);
    }
    if (id === "Price1") {
      setValuePrice1(e.target.value);
    }

    
    if (id === "Sub_Category_Name") {
      setValue2(e.target.value);
    }
    if (id === "Sub_Category") {
      setValueSub_Category(e.target.value);
    }

    if (id === "Description") {
      setValue3(e.target.value);
    }
  };
  const handleTypeSelect = (e) => {
    console.log(e);
    setSelectedOption(e.value);
    setKey2(e.label);
  };
  const handleTypeSelectBestSeller = (e) => {
    setKey3(e.value);
  };
  const fetchData = () => {
    axios.get(BASEURL + "Products/").then((response) => {
      console.log("getaboutus " + JSON.stringify(response.data));
      setRes(response.data.Products);
      console.log("getaboutus " + JSON.stringify(response.data.Products));
    });
    axios.get(BASEURL + "Products/Categories/").then((response) => {
      console.log(response.data[0].Name);
      var options = [];
      response.data.map((cat) => options.push({ value: cat.id, label: cat.Name }));

      setCategories(options);
    });
  };
  useEffect(() => {
    fetchData();
    var optionsBEST = [
      { value: "0", label: "NO" },
      { value: "1", label: "YES" },
    ];
    setIsBestSeller(optionsBEST);
    //console.log('state', constants.redPanda);
  }, []);

  let imagearray = [];

  const handleFileInput = (e) => {
    console.log("handleFileInput working!");
    formData.delete("imageval");
    formData.append("imageval", e.target.files[0]);
    formData.delete("imgname");

    formData.append("imgname", IMAGEURL + e.target.files[0].name);

    setimagesUpladed(e.target.files[0]);
    setImage(IMAGEURL + e.target.files[0].name);
  };
  const handleFileexel = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Process the selected Excel file here
      // You can read the file content using a library like xlsx.js

      // For example, you can use the xlsx library to read the Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        // Now you can parse the fileData using xlsx or any other library
        // and insert the data into your database
        parseExcelData(fileData);
      };

      // Read the selected file as binary
      reader.readAsBinaryString(selectedFile);
    }
  };

  const parseExcelData = (fileData) => {
    // Use a library like xlsx to parse the fileData and extract the data
    // For example:
    const workbook = read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const data = utils.sheet_to_json(sheet);

    // Now you have the Excel data in the 'data' variable
    // You can insert it into your database using an API request to your Node.js server
    // Send the 'data' to your server for database insertion
    sendExcelDataToServer(data);
  };

  const sendExcelDataToServer = (data) => {
    // Send an HTTP request to your Node.js server with the extracted data
    // Use axios or another HTTP library for the request

    axios
      .post(BASEURL + "Products//upload-json", { data })
      .then((response) => {
        // Handle the response from the server
        alert("Data inserted successfully");
        fetchData();
        setPricemodal(false);
      })
      .catch((error) => {
        console.error("Error inserting data", error);
      });
  };

  // ...existing code...

  // below function will be called when user
  // click on submit button .
  const handleSubmit = (e) => {
    //e.preventDefault();

    console.log("values Name " + imagesUpladed);
    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("NameInKannada");
    formData.append("NameInKannada", NameInKannada);
    formData.delete("Quantity");
    formData.append("Quantity", Quantity);
    formData.delete("Category");
    formData.append("Category", Category);
    formData.delete("CategoryId");
    formData.append("CategoryId", selectedOption);
    formData.delete("Best_Seller");
    formData.append("Best_Seller", Best_Seller);
    formData.delete("rowid");
    formData.append("rowid", rowid);
    formData.delete("Price");
    formData.append("Price", Price);
    formData.delete("Price1");
    formData.append("Price1", Price1);
    formData.delete("Sub_Category_Name");
    formData.append("Sub_Category_Name", Sub_Category_Name);
    formData.delete("Sub_Category");
    formData.append("Sub_Category", Sub_Category);

    formData.delete("Description");
    formData.append("Description", Description);
    formData.delete("imgname");
    formData.append("imgname", images);
    if (imagesUpladed != "" && imagesUpladed != null) {
      formData.append("imageval", imagesUpladed);
    }

    axios({
      method: "post",
      url: BASEURL + "Products/updateProducts",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      setEditmodal(false);
      fetchData();
      //window.location.reload();
    });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
  };
  const handleSubmitInsert = (e) => {
    //e.preventDefault();

    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("NameInKannada");
    formData.append("NameInKannada", NameInKannada);
    formData.delete("Quantity");
    formData.append("Quantity", Quantity);
    formData.delete("Category");
    formData.append("Category", Category);
    formData.delete("CategoryId");
    formData.append("CategoryId", selectedOption);
    formData.delete("Best_Seller");
    formData.append("Best_Seller", Best_Seller);
    formData.delete("rowid");
    formData.append("rowid", rowid);
    formData.delete("Price");
    formData.append("Price", Price);

    formData.delete("Price1");
    formData.append("Price1", Price1);
    
    formData.delete("Sub_Category_Name");
    formData.append("Sub_Category_Name", Sub_Category_Name);
    formData.delete("Sub_Category");
    formData.append("Sub_Category", Sub_Category);

    formData.delete("Description");
    formData.append("Description", Description);
    formData.delete("imgname");
    formData.append("imgname", images);
    if (imagesUpladed != "" && imagesUpladed != null) {
      formData.append("imageval", imagesUpladed);
    }

    axios({
      method: "post",
      url: BASEURL + "Products/insertProducts",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      setInsertmodal(false);
      fetchData();
      //window.location.reload();
    });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <div className="bg-light" style={{ justifyContent: "left", zIndex: "1500" }}>
          <Modal style={{ zIndex: 1500 }} show={insertmodal} onHide={() => handleInsertModal()}>
            <Modal.Header closeButton onClick={() => handleInsertCloseModal()}>
              Add New Product
            </Modal.Header>
            <Modal.Body>
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> Product Name</span>
                <input
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Name"
                  type="text"
                />
              </div>
              <br />
              <span style={{ fontSize: 14 }}> Product Name In Kannada</span>
              <div className="form-group ">
                <input
                  id="NameInKannada"
                  name="NameInKannada"
                  value={NameInKannada}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="ಕನ್ನಡ "
                  type="text"
                  style={{ fontFamily: "''Anek Kannada', sans-serif'" }}
                />
              </div>
              <br />
              <span style={{ fontSize: 14 }}> Quantity</span>
              <div className="form-group ">
                <input
                  id="Quantity"
                  name="Quantity"
                  value={Quantity}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Quantity"
                  type="text"
                />
              </div>
              <br />
              <div style={{ fontSize: 16 }} className="form-group ">
                <span style={{ fontSize: 14 }}> Category</span>
                <Select
                  onChange={handleTypeSelect}
                  style={{ fontSize: 18 }}
                  options={Categories}
                  defaultValue={{ label: Category, value: Category }}
                />
              </div>
              <br />
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> Variant ID</span>

                <input
                  id="Sub_Category"
                  name="Variant_ID"
                  value={Sub_Category}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Variant ID"
                  type="text"
                />
              </div>{" "}
              <br />
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> Variant Name</span>

                <input
                  id="Sub_Category_Name"
                  name="Variant_Name"
                  value={Sub_Category_Name}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Variant Name"
                  type="text"
                />
              </div>
              <br />
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> MRP</span>
                <input
                  id="Price1"
                  name="Price1"
                  value={Price1}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="MRP"
                  type="text"
                />
              </div>
              <br />

              <br />
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> Price</span>
                <input
                  id="Price"
                  name="Price"
                  value={Price}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Price"
                  type="text"
                />
              </div>
              <br />
              <div className=" ">
                <span style={{ fontSize: 14 }}> Is Home Screen Product?</span>
                <Select
                  onChange={handleTypeSelectBestSeller}
                  style={{ fontSize: 15 }}
                  options={IsBestSeller}
                  defaultValue={{ label: "NO", value: "0" }}
                />
              </div>
              <br />
              <div className="form-group ">
                <span style={{ fontSize: 14 }}> Product Description</span>
                <input
                  id="description"
                  name="description"
                  value={Description}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="description"
                  type="text"
                />
              </div>
              <br />
              {/* <img src={images} style={{ height: 100, padding: 12 }} /> */}
              {/* <div className="form-group input-group">
                <span className="input-group-text"> Images</span>

                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileexel}
                  style={{ display: "none" }}
                  ref={(input) => (fileInputRef = input)} // Add a reference to the input element
                />
                <Button
                  title="Upload Excel File"
                  onPress={() => fileInputRef.click()} // Trigger file input click
                />
              </div> */}
              <br />
          <img src={images} style={{ height: 100, padding: 12 }} />
          <div className="form-group input-group">
            <span className="input-group-text"> Images</span>

            <input type="file" name="imgfile" multiple onChange={handleFileInput} />
          </div>
          <br />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleInsertCloseModal()}>Close</Button>
              <Button onClick={() => handleSubmitInsert()}>Save</Button>
            </Modal.Footer>
          </Modal>

          <Modal style={{ zIndex: 1500 }} show={pricemodal} onHide={() => openpriceModal()}>
            <Modal.Header closeButton onClick={() => closepriceModal()}>
              Upload Price list
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="input-group-text" style={{ margin: 20 }}>
                  {" "}
                  Uploads exel file
                </div>
                <input
                  type="file"
                  style={{ margin: 20 }}
                  name="exel"
                  multiple
                  onChange={handleFileexel}
                />
              </div>
              <br />
            </Modal.Body>
          </Modal>
          {/* </form> */}
        </div>

        <div
          style={{
            width: "98%",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          {/* <DataTable
 
              data={res}
              columns={columns}
              theme="light"
              customStyles={customStyles}
              pagination
              onSelectedRowsChange={handleChangeRow}
              
            /> */}
          <SoftButton
            style={{ marginLeft: 10, marginBottom: -100, zIndex: 1000 }}
            onClick={() => handleInsertModal()}
            color="dark"
            variant="gradient"
          >
            Add New Product
          </SoftButton>

          <SoftButton
            style={{ marginLeft: 10, marginBottom: -100, zIndex: 1000 }}
            onClick={() => openpriceModal()}
            color="primary"
            variant="gradient"
          >
            Price uploads
          </SoftButton>
          <Table data={res} columns={columns} click={clickhandler} />
        </div>
      </SoftBox>
      <Modal style={{ zIndex: 1500 }} show={editmodal} onHide={() => handleEditModal()}>
        <Modal.Header closeButton onClick={() => handleEditCloseModal()}>
          <span
            style={{
              margin: 5,
              textAlign: "left",
              fontSize: "0.85rem",
              fontWeight: "900",

              color: " #8392ab",
              textTransform: "uppercase",
            }}
          >
            {" "}
            Edit in Product{" "}
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> Product Name</span>
            <input
              id="Name"
              name="Name"
              value={Name}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Name"
              type="text"
            />
          </div>
          <br />
          <span style={{ fontSize: 14 }}> Product Name In Kannada</span>
          <div className="form-group ">
            <input
              id="NameInKannada"
              name="NameInKannada"
              value={NameInKannada}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Name In Kannada"
              type="text"
              style={{ fontFamily: "'Nudi 05 k'" }}
            />
          </div>
          <br />
          <span style={{ fontSize: 14 }}> Quantity</span>
          <div className="form-group ">
            <input
              id="Quantity"
              name="Quantity"
              value={Quantity}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Quantity"
              type="text"
            />
          </div>
          <br />
          <div style={{ fontSize: 16 }} className="form-group ">
            <span style={{ fontSize: 14 }}> Category</span>
            <Select
              onChange={handleTypeSelect}
              style={{ fontSize: 18 }}
              options={Categories}
              defaultValue={{ label: Category, value: Category }}
            />
          </div>
          <br />
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> Variant ID</span>

            <input
              id="Sub_Category"
              name="Variant_ID"
              value={Sub_Category}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Variant ID"
              type="text"
            />
          </div>{" "}
          <br />
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> Variant Name</span>

            <input
              id="Sub_Category_Name"
              name="Variant_Name"
              value={Sub_Category_Name}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Variant Name"
              type="text"
            />
          </div>
          <br />
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> MRP</span>
            <input
              id="Price1"
              name="Price1"
              value={Price1}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="MRP"
              type="text"
            />
          </div>
          <br />
          <br />
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> Price</span>
            <input
              id="Price"
              name="Our Price"
              value={Price}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="Price"
              type="text"
            />
          </div>
          <br />
          <div className=" ">
            <span style={{ fontSize: 14 }}> Is Home Screen Product?</span>
            <Select
              onChange={handleTypeSelectBestSeller}
              style={{ fontSize: 15 }}
              options={IsBestSeller}
              defaultValue={{ label: Best_Seller == 1 ? "Yes" : "NO", value: Best_Seller }}
            />
          </div>
          <br />
          <div className="form-group ">
            <span style={{ fontSize: 14 }}> Product Description</span>
            <input
              id="description"
              name="description"
              value={Description}
              onChange={(e) => handleChange(e)}
              className="form-control"
              placeholder="description"
              type="text"
            />
          </div>
          <br />
          <img src={images} style={{ height: 100, padding: 12 }} />
          <div className="form-group input-group">
            <span className="input-group-text"> Images</span>

            <input type="file" name="imgfile" multiple onChange={handleFileInput} />
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
            className="btn btn-outer-primary"
            onClick={() => handleEditCloseModal()}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
            className="btn btn-outer-primary"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};
const customStyles = {
  rows: {
    style: {
      color: "#344767",
      fontSize: "0.875rem",
      margin: "0",
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontWeight: "100",
      lineHeight: "1.5",
      textTransform: "uppercase",
      letterSpacing: "0.02857em",
      opacity: "1",
      textTransform: "none",
      verticalAlign: "unset",
    },
  },
  headCells: {
    style: {
      paddingTop: "12px",
      paddingBottom: "10px",
      paddingLeft: "24px",
      paddingRight: "24px",
      textAlign: "left",
      fontSize: "0.65rem",
      fontWeight: "700",
      borderBottom: "0.0625rem solid #e9ecef",
      opacity: "0.9",
      background: "transparent",
      color: " #8392ab",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      paddingLeft: "24px",
      paddingRight: "24px",
      fontWeight: "400",
    },
  },
};

export default Products;
