import Products from "pages/Products";
import Tables from "layouts/tables";
import Customer from "pages/customer";
import Orders from "pages/orders";
import Setting from "pages/Setting";
import Categories from "pages/Categories";
import SignIn from "layouts/billing";
import Transaction from "pages/Transaction";
import Login from "pages/Login";

// T T Agencies icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import DeliveryCharges from "pages/DeliveryCharges";

import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Basket from "examples/Icons/Basket";
import Images from "examples/Icons/Image";

import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";

const routes = [
  {
    type: "collapse",
    name: "Products",
    key: "Products",
    route: "/Products",
    icon: <Shop size="12px" />,
    component: <Products />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    route: "/Orders",
    icon: <Basket size="12px" />,
    component: <Orders />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "Transaction",
    route: "/Transaction",
    icon: <Cube size="12px" />,
    component: <Transaction />,
    noCollapse: true,
  },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Customers",
    key: "customer",
    route: "/customer",
    icon: <CustomerSupport size="12px" />,
    component: <Customer />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "Categories",
    route: "/Categories",
    icon: <Document size="12px" />,
    component: <Categories />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Delivery Charges",
    key: "DeliveryCharges",
    route: "/DeliveryCharges",
    icon: <SpaceShip size="12px" />,
    component: <DeliveryCharges />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "Setting",
    route: "/Setting",
    icon: <Settings size="12px" />,
    component: <Setting />,
    noCollapse: true,
  },
  {
    type: "noCollapse",
    name: "Login",
    key: "Login",
    route: "/Login",
    icon: <Login size="12px" />,
    component: <Login />,
    noCollapse: true,
  },
];

export default routes;
