import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { IMAGEURL, BASEURL } from "../config";
import DataTable from "react-data-table-component";
import SoftBox from "components/SoftBox";
import Table from "./DataTable";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const Categories = () => {
  const clickhandler = (name) => console.log("delete", name);

  const [Name, setTitle] = useState("");
  const [color, setKey1] = useState("#FEF6ED,#EAB174");
  const [editmodal, setEditmodal] = useState(false);
  const [insertmodal, setInsertmodal] = useState(false);
  const [res, setRes] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowid, setRowid] = useState("");
  const [imgFile, setImgFile] = useState(null); // Define imgFile state

  const handleChangeRow = (value) => {
    setSelectedRows(value);
  };

  const editRow = (event, Name, color, image, rowid) => {
    setEditmodal(true);
    setTitle(Name);
    setKey1(color);
    setImage(image);
    setRowid(rowid);
  };
  const deleteRow = (rowId) => {
    axios({
      method: "post",
      url: BASEURL + "products/deleteCategories",
      data: { categoryId: rowId },
    }).then((res) => {
      if (res.data.message === "Category deleted successfully") {
        alert("Category deleted successfully");
        setEditmodal(false);
        fetchData();
        window.location.reload(); // Reload the page after successful deletion
      } else {
        alert("Error deleting category");
      }
    }).catch((error) => {
      console.error("Error deleting category:", error);
      alert("An error occurred while deleting the category");
    });
  };
  

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "100px",
        grow: 2,
      },
      {
        name: "Action",
        width: "130px",
        backgroundColor: "red",
        cell: (row) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <span
              style={{ textAlign: "center", marginLeft: "5px" }}
              type="button"
              id={row.id}
              onClick={(event) => {
                editRow(event, row.Name, row.color, row.image, row.id);
              }}
            >
              <SoftButton variant="text" color="dark">
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
            </span>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <Button variant="danger" onClick={() => deleteRow(row.id)}>
              Delete
            </Button>
          </div>
        ),
        button: true,
      },
      {
        name: "Name",
        selector: (row) => row.Name,
        sortable: true,
        width: "220px",
        grow: 2,
      },
      {
        cell: (row) => <img style={{ borderRadius: 10, height: 40 }} src={row.image} alt={row.Name} />,
      },
      {
        name: "color",
        selector: (row) => row.color,
        sortable: true,
        width: "180px",
        grow: 2,
      },
    ],
    []
  );

  const formData = new FormData();

  const handleEditModal = () => {
    setEditmodal(true);
  };

  const handleEditCloseModal = () => {
    setEditmodal(false);
  };

  const handleInsertModal = () => {
    setInsertmodal(true);
  };

  const handleInsertCloseModal = () => {
    setInsertmodal(false);
  };

  const openModal = () => {
    setInsertmodal(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setTitle(value);
    }
    if (id === "color") {
      setKey1(value);
    }
  };

  const fetchData = () => {
    axios.get(BASEURL + "products/Categories/").then((response) => {
      setRes(response.data);
      setTitle("");
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileInput = (e) => {
    setImgFile(e.target.files[0]); // Update imgFile state with the selected file
    formData.append("imgfile", e.target.files[0], e.target.files[0].name);
    formData.append("image", IMAGEURL + e.target.files[0].name);
    setImage(IMAGEURL + e.target.files[0].name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.delete("image");
    formData.append("image", image);
    formData.append("imageurl", image);
    formData.append("imageval", imgFile); // Use imgFile in formData instead of imgfile
    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("color");
    formData.append("color", color);
    formData.append("rowid", rowid);

    axios({
      method: "post",
      url: BASEURL + "products/updateCat",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    })
      .then((res) => {
        setEditmodal(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const insertCategories = () => {
    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("image");
    formData.append("image", image);
    formData.delete("color");

    formData.append("color", color);
    formData.append("imageval", imgFile);
    axios({
      method: "post",
      url: BASEURL + "products/IntsertCat",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      setInsertmodal(false);
      fetchData();
    });
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <div className="bg-light" style={{ justifyContent: "left", zIndex: "1500" }}>
          <Modal style={{ zIndex: 1500 }} show={insertmodal} onHide={handleInsertCloseModal}>
            <Modal.Header closeButton>
              Add
            </Modal.Header>
            <Modal.Body>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Category Name </span>
                </div>
                <input
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Title"
                  type="text"
                />
              </div>
              <br />
              <hr />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Color </span>
                </div>
                <select
                  name="color"
                  value={color}
                  onChange={handleChange}
                  className="form-control"
                  id="color"
                >
                  <option value="#EEF7F1,#82C69A">Color Scheme - 1</option>
                  <option value="#F4EBF7,#D3B0E0">Color Scheme - 2</option>
                  <option value="#FDE8E4,#F7A593">Color Scheme - 3</option>
                  <option value="#FEF6ED,#EAB174">Color Scheme - 4</option>
                  <option value="#faeed2,#f3bf4a">Color Scheme - 5</option>
                  <option value="#EEF0E3,#e1eda1">Color Scheme - 6</option>
                  <option value="#FFF8E5,#f1aca0">Color Scheme - 7</option>
                  <option value="#F3F3F3,#a5d193">Color Scheme - 8</option>
                  <option value="#dce7ce,#B0D58B">Color Scheme - 9</option>
                  <option value="#F1DAD6,#f1aca0">Color Scheme - 10</option>
                </select>
              </div>
              <br />
              <hr />
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> image</span>
                </div>
                <input type="file" name="imgfile" multiple onChange={handleFileInput} />
              </div>
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleInsertCloseModal}>Close</Button>
              <Button onClick={insertCategories}>Save</Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div style={{ width: "98%", justifyContent: "center", justifyItems: "center" }}>
          <SoftButton
            style={{ marginLeft: 10, marginBottom: -100, zIndex: 1000 }}
            onClick={handleInsertModal}
            color="dark"
            variant="gradient"
          >
            Add New Category
          </SoftButton>
          <Table data={res} columns={columns} click={clickhandler} />
        </div>

        <Modal style={{ zIndex: 1500 }} show={editmodal} onHide={handleEditModal}>
          <Modal.Header closeButton>
            <span style={{ margin: 5, textAlign: "left", fontSize: "0.85rem", fontWeight: "900", color: " #8392ab", textTransform: "uppercase" }}>Edit in Product</span>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group ">
              <input
                id="Name"
                name="Name"
                value={Name}
                onChange={handleChange}
                className="form-control"
                placeholder="Name"
                type="text"
              />
            </div>
            <br />
            <div className="form-group ">
              <select
                name="color"
                value={color}
                onChange={handleChange}
                className="form-control"
                id="color"
              >
                <option value="#EEF7F1,#82C69A">Color Scheme - 1</option>
                <option value="#F4EBF7,#D3B0E0">Color Scheme - 2</option>
                <option value="#FDE8E4,#F7A593">Color Scheme - 3</option>
                <option value="#FEF6ED,#EAB174">Color Scheme - 4</option>
                <option value="#faeed2,#f3bf4a">Color Scheme - 5</option>
                <option value="#EEF0E3,#e1eda1">Color Scheme - 6</option>
                <option value="#FFF8E5,#f1aca0">Color Scheme - 7</option>
                <option value="#F3F3F3,#a5d193">Color Scheme - 8</option>
                <option value="#dce7ce,#B0D58B">Color Scheme - 9</option>
                <option value="#F1DAD6,#f1aca0">Color Scheme - 10</option>
              </select>
            </div>
            <br />
            <img src={image} style={{ height: 100, padding: 12 }} alt="Category" />
            <div className="form-group input-group">
              <span className="input-group-text"> Image</span>
              <input type="file" name="imgfile" multiple onChange={handleFileInput} />
            </div>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ fontSize: 14, backgroundColor: "#17c1e8" }} className="btn btn-outer-primary" onClick={handleEditCloseModal}>Close</Button>
            <Button style={{ fontSize: 14, backgroundColor: "#17c1e8" }} className="btn btn-outer-primary" onClick={handleSubmit}>Save</Button>
          </Modal.Footer>
        </Modal>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Categories;
