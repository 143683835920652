import React from "react";
import styled from "styled-components";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import Icon from "@mui/material/Icon";
const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 34px;

  border-radius: 5px;

  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  font-size: 14px;
`;

const ClearButton = styled.button`
  border-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  border: 1px solid #e5e5e5;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <SoftBox pr={1}>
      <SoftInput
        placeholder="Type here..."
        icon={{ component: "search", direction: "left" }}
        value={filterText}
        onChange={onFilter}
      />
    </SoftBox>
    <ClearButton onClick={onClear}>
      {" "}
      <Icon>clear</Icon>
    </ClearButton>
  </>
);

export default FilterComponent;
