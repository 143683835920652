/**
=========================================================
* T T Agencies - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// T T Agencies components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/cr.jpg";

function SignIn() {
  const [Mobile, setMobile] = useState("");

  const [Password, setPassword] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "Mobile") {
      setMobile(e.target.value);
    }
    if (id === "Password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    console.log(Mobile);
  
    // Create a request body object
    const requestBody = {
      Mobile: Mobile,
      Password: Password,
    };
  
    // Create fetch options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };
  
    // Make the POST request using fetch
    fetch("https://ttapi.cannytechnology.com/products/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.user.length > 0) {
          document.body.setAttribute("dir", "rtl");
          localStorage.setItem("log", data.user[0].id);
          window.location = "/Orders";
        } else {
          alert("Invalid Mobile and Password");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout title="T T Agencies" image={curved9}>
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Mobile
            </SoftTypography>
          </SoftBox>
          <SoftInput
            id="Mobile"
            name="Mobile"
            value={Mobile}
            onChange={(e) => handleChange(e)}
            type="number"
            placeholder="Mobile"
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            id="Password"
            name="Password"
            value={Password}
            onChange={(e) => handleChange(e)}
            type="Password"
            placeholder="Password"
          />
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton onClick={() => handleSubmit()} variant="gradient" color="info" fullWidth>
            sign in
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
