import React, { useMemo } from "react";

import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";

const Table = (props) => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    (item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={props.columns}
      data={filteredItems}
      theme="light"
      customStyles={customStyles}
      striped
      pagination
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
  );
};
const customStyles = {
  rows: {
    style: {
      color: "#344767",
      fontSize: "0.875rem",
      margin: "0",
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontWeight: "100",
      lineHeight: "1.5",
      textTransform: "uppercase",
      letterSpacing: "0.02857em",
      opacity: "1",
      textTransform: "none",
      verticalAlign: "unset",
    },
  },
  headCells: {
    style: {
      paddingTop: "12px",
      paddingBottom: "10px",
      paddingLeft: "24px",
      paddingRight: "24px",
      textAlign: "left",
      fontSize: "0.65rem",
      fontWeight: "700",
      borderBottom: "0.0625rem solid #e9ecef",
      opacity: "0.7",
      background: "transparent",
      color: " #8392ab",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      paddingLeft: "24px",
      paddingRight: "24px",
      fontWeight: "400",
    },
  },
};
export default Table;
