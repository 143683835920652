import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { IMAGEURL, BASEURL } from "../config";
import DataTable from "react-data-table-component";
import SoftBox from "components/SoftBox";
import Table from "./DataTable";
import SoftButton from "components/SoftButton";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const Transaction = () => {
  // `id`, `Name`, `NameInKannada`,
  //  `Quantity`, `Type_One_Daily`, `Price`, `Discount`, `category_id`,
  //   `Category`, `Sub_Category`, `Sub_Category_Name`, `Best_Seller`,
  //    `images`, `Description`, `Updated_Date`
  const clickhandler = (name) => console.log("delete", name);

  const [Name, setTitle] = useState("");
  const [NameInKannada, setDescription] = useState("");
  const [Quantity, setKey1] = useState("");
  const [Price, setValue1] = useState("");
  const [Category, setKey2] = useState("");
  const [Sub_Category_Name, setValue2] = useState("");
  const [Best_Seller, setKey3] = useState("");
  const [Description, setValue3] = useState("");

  const [editmodal, setEditmodal] = useState(false);

  const [insertmodal, setInsertmodal] = useState(false);

  const [res, setRes] = useState([]);

  const [images, setImage] = useState(null);
  // const [typeval , setTypeval] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowid, setRowid] = useState("");

  const handleChangeRow = (value) => {
    setSelectedRows(value);
  };
  const editRow = (
    event,
    Name,
    NameInKannada,
    Quantity,
    Category,
    Best_Seller,
    Price,
    Sub_Category_Name,
    Description,
    images,
    rowid
  ) => {
    console.log(event.target.id + " " + Name);
    //setEditid(event.target.id);
    setEditmodal(true);

    setTitle(Name);
    setDescription(NameInKannada);
    setKey1(Quantity);
    setKey2(Category);
    setKey3(Best_Seller);
    setValue1(Price);
    setValue2(Sub_Category_Name);
    setValue3(Description);
    setImage(images);
    setRowid(rowid);
  };

  const viewRow = (event) => {
    console.log(event.target.id);
  };
  const columns = useMemo(
    () => [
      {
        name: "Date",
        selector: (row) => row.Created_Date,
        sortable: true,
        width: "140px",
        grow: 2,
      },
      {
        name: "Id",
        selector: (row) => row.id,
        width: "100px",
        sortable: true,
      },
      {
        name: "Customer",
        selector: (row) => row.Name,
        width: "230px",
        sortable: true,
      },

      {
        name: "Total amount",
        selector: (row) => row.Cart_amount,
        sortable: true,
        width: "120px",
        grow: 2,
      },
      {
        name: "RazorPay Id",
        selector: (row) => row.RazorPay_Id,
        width: "230px",
        sortable: true,
      },
      {
        name: "RazorPay Ref",
        selector: (row) => row.RazorPay_Ref,
        width: "230px",
        sortable: true,
      },
    ],
    []
  );

  const formData = new FormData();
  let imgname = "test";

  const handleEditModal = () => {
    setEditmodal(true);
  };

  const handleEditCloseModal = () => {
    setEditmodal(false);
  };

  const handleInsertModal = () => {
    setInsertmodal(true);
  };

  const handleInsertCloseModal = () => {
    setInsertmodal(false);
  };

  const openModal = () => {
    setInsertmodal(true);
  };

  // function to update state of name with
  // value enter by user in form
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "Name") {
      setTitle(e.target.value);
    }
    if (id === "NameInKannada") {
      setDescription(e.target.value);
    }

    if (id === "Quantity") {
      setKey1(e.target.value);
    }
    if (id === "Category") {
      setKey2(e.target.value);
    }
    if (id === "Best_Seller") {
      setKey3(e.target.value);
    }
    if (id === "Price") {
      setValue1(e.target.value);
    }
    if (id === "Sub_Category_Name") {
      setValue2(e.target.value);
    }
    if (id === "Description") {
      setValue3(e.target.value);
    }
  };

  const fetchData = () => {
    axios.get(BASEURL + "Products/Transaction").then((response) => {
      setRes(response.data.Transaction);
      console.log("getaboutus " + JSON.stringify(response));
    });
  };
  useEffect(() => {
    fetchData();

    //console.log('state', constants.redPanda);
  }, []);

  let imagearray = [];

  const handleFileInput = (e) => {
    console.log("handleFileInput working!");
    console.log(e.target.files);

    let temparr = [];

    //formData.append('imgfile', e.target.files[0], e.target.files[0].name);
    //setImage(e.target.files[0]);
    // for(var i=0; i < e.target.files.length; i++ )
    // {
    //   //setImage(e.target.files[i]);
    //   imgname = e.target.files[i].name;

    //   imagearray.push(e.target.files[i].name);
    //   formData.append('imageval', e.target.files[i]);

    //   var jsonval = JSON.stringify(Object.assign({}, imagearray)); //JSON.parse(imagearray);
    //   console.log(jsonval);
    //   temparr.push(jsonval);

    //   imagearray = [];

    // }
    formData.delete("imageval");
    formData.append("imageval", e.target.files[0]);

    //formData.append("imgname", new Date().valueOf());
    formData.delete("imgname");
    formData.append("imgname", IMAGEURL + e.target.files[0].name);
  };

  // below function will be called when user
  // click on submit button .
  const handleSubmit = (e) => {
    //e.preventDefault();

    console.log("values Name " + imgname);
    formData.delete("Name");
    formData.append("Name", Name);
    formData.delete("NameInKannada");
    formData.append("NameInKannada", NameInKannada);
    formData.delete("Quantity");
    formData.append("Quantity", Quantity);
    formData.delete("Category");
    formData.append("Category", Category);
    formData.delete("Best_Seller");
    formData.append("Best_Seller", Best_Seller);
    formData.delete("rowid");
    formData.append("rowid", rowid);
    formData.delete("Price");
    formData.append("Price", Price);
    formData.delete("Sub_Category_Name");
    formData.append("Sub_Category_Name", Sub_Category_Name);
    formData.delete("Description");
    formData.append("Description", Description);

    axios({
      method: "post",
      url: BASEURL + "users/updateProducts",
      data: formData,
      headers: { "content-type": "multipart/form-data" },
    }).then((res) => {
      setEditmodal(false);
      fetchData();
      //window.location.reload();
    });

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
        <div className="bg-light" style={{ justifyContent: "left", zIndex: "1500" }}>
          <Modal style={{ zIndex: 1500 }} show={insertmodal} onHide={() => handleInsertModal()}>
            <Modal.Header closeButton onClick={() => handleInsertCloseModal()}>
              Edit
            </Modal.Header>
            <Modal.Body>
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Product Name </span>
                </div>
                <input
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Title"
                  type="text"
                />
              </div>
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Description </span>
                </div>
                <textarea
                  id="NameInKannada"
                  name="NameInKannada"
                  className="form-control"
                  placeholder="Description"
                  value={NameInKannada}
                  onChange={(e) => handleChange(e)}
                  style={{ height: 100 }}
                ></textarea>
                {/* <input name="" class="form-control" placeholder="Email address" type="email" /> */}
              </div>
              <br />
              <hr />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Key </span>
                </div>
                <input
                  id="Quantity"
                  name="Quantity"
                  value={Quantity}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />

                <div className="input-group-prepend">
                  <span className="input-group-text"> Value </span>
                </div>
                <input
                  id="Price"
                  name="Price"
                  value={Price}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />
              </div>{" "}
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Key </span>
                </div>
                <input
                  id="Category"
                  name="Category"
                  value={Category}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />

                <div className="input-group-prepend">
                  <span className="input-group-text"> Value </span>
                </div>
                <input
                  id="Sub_Category_Name"
                  name="Sub_Category_Name"
                  value={Sub_Category_Name}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />
              </div>
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Key </span>
                </div>
                <input
                  id="Best_Seller"
                  name="Best_Seller"
                  value={Best_Seller}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />

                <div className="input-group-prepend">
                  <span className="input-group-text"> Value </span>
                </div>
                <input
                  id="Description"
                  name="Description"
                  value={Description}
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  type="text"
                />
              </div>
              <br />
              <hr />
              <br />
              <div className="form-group input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Images</span>
                </div>
                <input type="file" name="imgfile" multiple onChange={handleFileInput} />
                {/* <input name="" class="form-control" placeholder="Email address" type="email" /> */}
              </div>{" "}
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => handleInsertCloseModal()}>Close</Button>
              <Button onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
          </Modal>

          {/* </form> */}
        </div>

        <div
          style={{
            width: "98%",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          {/* <DataTable
 
              data={res}
              columns={columns}
              theme="light"
              customStyles={customStyles}
              pagination
              onSelectedRowsChange={handleChangeRow}
              
            /> */}
          <Table data={res} columns={columns} click={clickhandler} />
        </div>

        <Modal style={{ zIndex: 1500 }} show={editmodal} onHide={() => handleEditModal()}>
          <Modal.Header closeButton onClick={() => handleEditCloseModal()}>
            <span
              style={{
                margin: 5,
                textAlign: "left",
                fontSize: "0.85rem",
                fontWeight: "900",

                color: " #8392ab",
                textTransform: "uppercase",
              }}
            >
              {" "}
              Edit in Product{" "}
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group ">
              <input
                id="Name"
                name="Name"
                value={Name}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Name"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="NameInKannada"
                name="NameInKannada"
                value={NameInKannada}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Name In Kannada"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="Quantity"
                name="Quantity"
                value={Quantity}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Quantity"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="Category"
                name="Category"
                value={Category}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Category"
                type="text"
              />
            </div>

            <br />
            <div className="form-group ">
              <input
                id="Sub_Category_Name"
                name="Sub_Category_Name"
                value={Sub_Category_Name}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Sub Category Name"
                type="text"
              />
            </div>
            <br />
            <div className="form-group ">
              <input
                id="Price"
                name="Price"
                value={Price}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Price"
                type="text"
              />
            </div>
            <br />
            <div className="form-group ">
              <input
                id="Best_Seller"
                name="Best_Seller"
                value={Best_Seller}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="Is Best Seller Product?"
                type="text"
              />
            </div>
            <br />
            <div className="form-group ">
              <input
                id="description"
                name="description"
                value={Description}
                onChange={(e) => handleChange(e)}
                className="form-control"
                placeholder="description"
                type="text"
              />
            </div>
            <br />

            <img src={images} style={{ height: 100, padding: 12 }} />

            <div className="form-group input-group">
              <span className="input-group-text"> Images</span>

              <input type="file" name="imgfile" multiple onChange={handleFileInput} />
            </div>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
              className="btn btn-outer-primary"
              onClick={() => handleEditCloseModal()}
            >
              Close
            </Button>
            <Button
              style={{ fontSize: 14, backgroundColor: "#17c1e8" }}
              className="btn btn-outer-primary"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};
const customStyles = {
  rows: {
    style: {
      color: "#344767",
      fontSize: "0.875rem",
      margin: "0",
      fontFamily: "Roboto",
      fontSize: "0.875rem",
      fontWeight: "100",
      lineHeight: "1.5",
      textTransform: "uppercase",
      letterSpacing: "0.02857em",
      opacity: "1",
      textTransform: "none",
      verticalAlign: "unset",
    },
  },
  headCells: {
    style: {
      paddingTop: "12px",
      paddingBottom: "10px",
      paddingLeft: "24px",
      paddingRight: "24px",
      textAlign: "left",
      fontSize: "0.65rem",
      fontWeight: "700",
      borderBottom: "0.0625rem solid #e9ecef",
      opacity: "0.9",
      background: "transparent",
      color: " #8392ab",
      textTransform: "uppercase",
    },
  },
  cells: {
    style: {
      paddingLeft: "24px",
      paddingRight: "24px",
      fontWeight: "400",
    },
  },
};

export default Transaction;
